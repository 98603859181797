@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@0,500;0,700&family=Roboto:ital,wght@0,400;0,700;1,400&display=swap')
@import './variables.styl'

$dot-color = #75688c
$dot-size = 1px
$dot-space = 25px
$background-gradient = linear-gradient(90deg, $default-background-color $dot-space - $dot-size, transparent 1%) center, linear-gradient($default-background-color $dot-space - $dot-size, transparent 1%) center, $dot-color

html
body
  height 100%
  width 100%

body
  align-items center
  background $background-gradient
  background-size $dot-space $dot-space
  color $default-text-color
  display flex
  font-family 'Poppins', sans-serif
  font-size 1em
  font-weight 600
  justify-content center
  letter-spacing 0.05em
  margin 0
  -moz-osx-font-smoothing grayscale
  -webkit-font-smoothing antialiased

  *
    -webkit-backface-visibility hidden
    -webkit-tap-highlight-color transparent

.Loading
  animation spin 2s infinite
  font-size 2rem

@keyframes spin
  from
    transform rotate(0deg)

  to
    transform rotate(360deg)
