@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@0,500;0,700&family=Roboto:ital,wght@0,400;0,700;1,400&display=swap);
html,
body {
  height: 100%;
  width: 100%;
}
body {
  align-items: center;
  background: linear-gradient(90deg, #3e3353 24px, transparent 1%) center, linear-gradient(#3e3353 24px, transparent 1%) center, #75688c;
  background-size: 25px 25px;
  color: #f2b8d4;
  display: flex;
  font-family: 'Poppins', sans-serif;
  font-size: 1em;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.05em;
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
body * {
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
}
.Loading {
  -webkit-animation: spin 2s infinite;
          animation: spin 2s infinite;
  font-size: 2rem;
}
@-webkit-keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

